import React from 'react'
import { useInView } from 'react-intersection-observer'
import styled from '@emotion/styled'
import mq from '../styles/mediaQueries'
import { graphql, useStaticQuery } from 'gatsby'

import HeroPageTemplate from '../templates/hero-page'
import Seo from '../components/seo'
import ParallaxComponent from '../components/Parallax'
import ContactForm from '../components/ContactForm'

import MeUndiesSVG from '../assets/logo/meundies-logo.svg'
import RowanSVG from '../assets/logo/rowan-logo.svg'

import { Subheading1 } from '../components/Typography'

import Layout from '../components/Layout'

const MeUndiesLogo = props => {
  return (
    <MeUndiesSVG
      {...props}
      style={{ display: 'block', width: '189px', height: '36px' }}
    />
  )
}

const RowanLogo = props => {
  return (
    <RowanSVG
      {...props}
      style={{ display: 'block', width: '234px', height: '28px' }}
    />
  )
}

const BackgroundWrapper = styled.div`
  background: #e5e5e5;
`

const ParallaxWrapper = styled.div``

const ParallaxLeftWrapper = styled.div`
  ${mq({
    '&:last-child': {
      paddingBottom: '0',
    },
    paddingRight: ['0', '36px', '120px', '312px', '312px'],
    paddingBottom: ['160px', '0', '0', '0', '0'],
  })};
`

const ParallaxRightWrapper = styled.div`
  ${mq({
    paddingLeft: ['0', '36px', '120px', '312px', '312px'],
    paddingBottom: ['160px', '0', '0', '0', '0'],
  })};
`

const SubHeader = styled.div`
  ${Subheading1}
  ${mq({
    gridColumnStart: ['1', '1', '1', '3', '3'],
    gridColumnEnd: ['5', '9', '13', '11', '11'],
    margin: ['92px 0 120px 0'],
    color: [
      'var(--terminal-400)',
      'var(--terminal-400)',
      'var(--terminal-500)',
      'var(--terminal-500)',
      'var(--terminal-500)',
    ],
  })};
`

const ContactFormWrapper = styled.div`
  ${mq({
    gridColumnStart: ['1', '1', '2', '2', '2'],
    gridColumnEnd: ['5', '9', '12', '12', '12'],
  })};
`

function Work() {
  const pageQuery = useStaticQuery(graphql`
    query {
      meundies: file(relativePath: { eq: "meundies.jpg" }) {
        ...squareImage
      }

      meundiesHeader: file(relativePath: { eq: "meundies-header.png" }) {
        ...squareImage
      }

      rowan: file(relativePath: { eq: "rowan.jpg" }) {
        ...squareImage
      }

      rowanHeader: file(relativePath: { eq: "rowan-header.png" }) {
        ...squareImage
      }
    }
  `)

  const [contactFormRef, contactFormInView] = useInView({
    triggerOnce: true,
    rootMargin: '200px 0px',
  })
  const pageTitle = 'Our Work'

  const firstLine = 'We work with the world’s top direct to consumer brands.'
  const secondLine =
    'Together we build technologies that help them win in the marketplace.'

  const MeUndiesText =
    'The first direct-to-consumer underwear brand, MeUndies partnered with Super Good to optimize the entire site, launching new features and streamlining complex integrations that grow their customizable and subscription-based business model.'
  const RowanText =
    'Rowan engaged with Super Good to help bring their heritage business into the ecommerce space. Building from the ground up, Rowan’s brick and mortar retail model was complemented by digital enterprise, growing into a worldwide distributed brand.'

  return (
    <BackgroundWrapper>
      <HeroPageTemplate
        theme={'day'}
        firstLine={firstLine}
        secondLine={secondLine}
        title={pageTitle}
        copyrightYear={'2022'}
      >
        <Seo
          title={pageTitle}
          description="Our team of developers have built and helped some of the best digital brands to achieve their goals and explore new ideas. Book a call with us today to find out how we can help."
          keywords={[
            `Ruby on Rails`,
            `Rails`,
            `React`,
            `Spree`,
            `Solidus`,
            `eCommerce`,
          ]}
        />
        <ParallaxWrapper>
          <ParallaxLeftWrapper>
            <ParallaxComponent
              position={'left'}
              image={pageQuery.meundies.childImageSharp}
              background={pageQuery.meundiesHeader.childImageSharp}
              logo={<MeUndiesLogo />}
              text={MeUndiesText}
              path="/work/meundies"
            />
          </ParallaxLeftWrapper>
          <ParallaxRightWrapper>
            <ParallaxComponent
              position={'right'}
              image={pageQuery.rowan.childImageSharp}
              background={pageQuery.rowanHeader.childImageSharp}
              logo={<RowanLogo />}
              text={RowanText}
              path="/work/rowan"
            />
          </ParallaxRightWrapper>
        </ParallaxWrapper>
        <Layout>
          <SubHeader>
            If development capacity wasn’t holding you back, what new heights
            would your business achieve?
          </SubHeader>
          <ContactFormWrapper ref={contactFormRef}>
            {contactFormInView ? <ContactForm /> : null}
          </ContactFormWrapper>
        </Layout>
      </HeroPageTemplate>
    </BackgroundWrapper>
  )
}

export default Work
